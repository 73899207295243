import { PhysicalNexusHeaderLevel } from 'app-header-toolbar/physical-nexus-header-level';
import {
  CustomersIcon,
  DashboardIcon,
  DatabaseIcon,
  Exemptions,
  FilingIcon,
  LocationIcon,
  NexusIcon,
  ProductIcon,
  RegisterIcon,
  SettingsIcon,
  TaxRulesIcon,
  TransactionsIcon,
} from 'components/icons';
import React from 'react';
import { CountryOptionsType, CountryStateOptionsType } from 'types/address';
import { getSortedCountries } from 'utils/address';

import { BLANK_ADDRESSES, NEXUS_ROUTES, PARTNER_ORG, TOOLS_AND_GUIDE } from './AppRoutes';

export interface ModuleInfo {
  name: string;
  icon: React.ReactNode;
  href: string;
  label: React.ReactNode;
  isBottomToolbar?: boolean;
  pageTitle?: string;
  minRole: 'Admin' | 'Owner' | 'Member';
}

export const ROUTES_WITH_NO_NAV: ModuleInfo[] = [
  {
    href: '/transactions/:id',
    name: 'Transaction Details',
    icon: null,
    label: 'Transaction Details',
    pageTitle: 'Transaction Details | Kintsugi',
    minRole: 'Member',
  },
  {
    href: '/invalid-addresses',
    name: '',
    icon: null,
    label: 'Cleanup Invalid Addresses',
    pageTitle: 'Cleanup Invalid Addresses | Kintsugi',
    minRole: 'Member',
  },
  {
    href: BLANK_ADDRESSES,
    name: '',
    icon: null,
    label: 'Blank Addresses',
    pageTitle: 'Blank Addresses | Kintsugi',
    minRole: 'Member',
  },
  {
    href: '/customers/:customerId',
    name: '',
    icon: null,
    label: 'Customer Details',
    pageTitle: 'Customer Details | Kintsugi',
    minRole: 'Member',
  },
  {
    href: '/integrations',
    name: '',
    icon: null,
    label: 'Integrations',
    pageTitle: 'Integrations | Kintsugi',
    minRole: 'Member',
  },
  {
    href: PARTNER_ORG,
    name: '',
    icon: null,
    label: 'Organizations',
    pageTitle: 'Organizations | Kintsugi',
    minRole: 'Member',
  },
  {
    href: NEXUS_ROUTES.COLLECTED_TRANSACTIONS,
    name: '',
    icon: null,
    label: 'Collected Transactions',
    pageTitle: 'Collected Transactions | Kintsugi',
    minRole: 'Member',
  },
];

export const APP_ROUTES: ModuleInfo[] = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    href: '/',
    label: 'Dashboard',
    pageTitle: 'Dashboard | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Nexus',
    icon: <NexusIcon />,
    href: NEXUS_ROUTES.EXPOSED,
    label: 'Nexus',
    pageTitle: 'Nexus | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Registrations',
    icon: <RegisterIcon />,
    href: '/registrations',
    label: 'Registrations',
    pageTitle: 'Registrations | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Filings',
    icon: <FilingIcon />,
    href: '/filing',
    label: 'Filings',
    pageTitle: 'Filings | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Customers',
    icon: <CustomersIcon />,
    href: '/customers',
    label: 'Customers',
    pageTitle: 'Customers | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Exemptions',
    icon: <Exemptions />,
    href: '/exemptions',
    label: 'Exemptions',
    pageTitle: 'Exemptions | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Transactions',
    icon: <TransactionsIcon />,
    href: '/transactions',
    label: 'Transactions',
    pageTitle: 'Transactions | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Products',
    icon: <ProductIcon />,
    href: '/products',
    label: 'Products',
    pageTitle: 'Products | Kintsugi',
    minRole: 'Member',
  },
  {
    name: 'Data Sources',
    icon: <DatabaseIcon />,
    href: '/source',
    label: 'Data Sources',
    pageTitle: 'Integrations & Imports | Kintsugi',
    minRole: 'Admin',
  },
  {
    href: '/presence',
    name: 'Presence',
    icon: <LocationIcon />,
    label: <PhysicalNexusHeaderLevel />,
    isBottomToolbar: false,
    pageTitle: 'Physical Presence | Kintsugi',
    minRole: 'Member',
  },
  {
    href: TOOLS_AND_GUIDE,
    name: 'Tools & Guide',
    icon: <TaxRulesIcon />,
    label: 'Tools & Guide',
    isBottomToolbar: true,
    pageTitle: 'Tools & Guide | Kintsugi',
    minRole: 'Member',
  },
  {
    href: '/configurations/:tab',
    name: 'Configuration',
    icon: <SettingsIcon />,
    label: 'Configuration',
    isBottomToolbar: true,
    pageTitle: 'Configuration | Kintsugi',
    minRole: 'Admin',
  },
];

export const supportedCountryStates = ['CA', 'MX', 'US'];

export const getStatesOrProvinces = (country_code: string) => {
  switch (country_code) {
    case 'US':
      return USStates;
    case 'CA':
      return CAStates;
    case 'MX':
      return MXStates;
    default:
      return [];
  }
};

export const USStates: CountryStateOptionsType[] = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AA', label: 'Armed Forces Americas' },
  { value: 'AP', label: 'Armed Forces Pacific' },
  { value: 'AE', label: 'Armed Forces Europe' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'PW', label: 'Palau' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const CAStates: CountryStateOptionsType[] = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
];

export const MXStates: CountryStateOptionsType[] = [
  { value: 'AG', label: 'Aguascalientes' },
  { value: 'BC', label: 'Baja California' },
  { value: 'BS', label: 'Baja California Sur' },
  { value: 'CM', label: 'Campeche' },
  { value: 'CS', label: 'Chiapas' },
  { value: 'CH', label: 'Chihuahua' },
  { value: 'CO', label: 'Coahuila' },
  { value: 'CL', label: 'Colima' },
  { value: 'DF', label: 'Ciudad de México' },
  { value: 'DG', label: 'Durango' },
  { value: 'GT', label: 'Guanajuato' },
  { value: 'GR', label: 'Guerrero' },
  { value: 'HG', label: 'Hidalgo' },
  { value: 'JA', label: 'Jalisco' },
  { value: 'MX', label: 'Estado de México' },
  { value: 'MI', label: 'Michoacán' },
  { value: 'MO', label: 'Morelos' },
  { value: 'NA', label: 'Nayarit' },
  { value: 'NL', label: 'Nuevo León' },
  { value: 'OA', label: 'Oaxaca' },
  { value: 'PU', label: 'Puebla' },
  { value: 'QT', label: 'Querétaro' },
  { value: 'QR', label: 'Quintana Roo' },
  { value: 'SL', label: 'San Luis Potosí' },
  { value: 'SI', label: 'Sinaloa' },
  { value: 'SO', label: 'Sonora' },
  { value: 'TB', label: 'Tabasco' },
  { value: 'TM', label: 'Tamaulipas' },
  { value: 'TL', label: 'Tlaxcala' },
  { value: 'VE', label: 'Veracruz' },
  { value: 'YU', label: 'Yucatán' },
  { value: 'ZA', label: 'Zacatecas' },
];

export const Countries: CountryOptionsType[] = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia (Plurinational State of)' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo (Democratic Republic of the)' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czechia' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran (Islamic Republic of)' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: "Korea (Democratic People's Republic of)" },
  { value: 'KR', label: 'Korea (Republic of)' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: "Lao People's Democratic Republic" },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia (Federated States of)' },
  { value: 'MD', label: 'Moldova (Republic of)' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland' },
  { value: 'US', label: 'United States of America' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela (Bolivarian Republic of)' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands (British)' },
  { value: 'VI', label: 'Virgin Islands (U.S.)' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
  { value: 'XK', label: 'Kosovo' },
];

export const CountryOptions = getSortedCountries(Countries);

export const CountriesByCode = Countries.reduce(
  (acc, country) => {
    acc[country.value] = country;
    return acc;
  },
  {} as {
    [key: string]: CountryOptionsType;
  }
);

export const USStatesByCode = USStates.reduce(
  (acc, state) => {
    acc[state.value] = state;
    return acc;
  },
  {} as {
    [key: string]: CountryStateOptionsType;
  }
);

export const CAStatesByCode = CAStates.reduce(
  (acc, state) => {
    acc[state.value] = state;
    return acc;
  },
  {} as {
    [key: string]: CountryStateOptionsType;
  }
);

export const NO_SALES_TAX_STATES = ['DE', 'MT', 'NH', 'OR', 'AB', 'YT', 'NT', 'NU', 'ON', 'NB', 'PE', 'NS', 'NL'];
