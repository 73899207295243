/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { EmailRequest } from '../models/EmailRequest';
import type { ValidationAddress } from '../models/ValidationAddress';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * Health Check
     * Health check
     * @returns any Successful Response
     * @throws ApiError
     */
    public static healthCheckHealthGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/health',
        });
    }
    /**
     * Integration Test
     * Health check
     * @returns any Successful Response
     * @throws ApiError
     */
    public static integrationTestIntegrationGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/integration',
        });
    }
    /**
     * Proxy Address Validation Search
     * @returns any Successful Response
     * @throws ApiError
     */
    public static proxyAddressValidationSearchV1AddressValidationSearchFieldsPost({
        requestBody,
    }: {
        requestBody: ValidationAddress,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/address_validation/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Proxy Address Validation Suggestions
     * @returns any Successful Response
     * @throws ApiError
     */
    public static proxyAddressValidationSuggestionsV1AddressValidationSuggestionsPost({
        requestBody,
    }: {
        requestBody: ValidationAddress,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/address_validation/suggestions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Proxy Address Validation Bulk Suggestions
     * @returns any Successful Response
     * @throws ApiError
     */
    public static proxyAddressValidationBulkSuggestionsV1AddressValidationBulkSuggestionsPost({
        requestBody,
    }: {
        requestBody: Array<Address>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/address_validation/bulk_suggestions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Internal Email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendInternalEmailV1InternalEmailSendPost({
        requestBody,
    }: {
        requestBody: EmailRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/internal/email/send',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Password
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendPasswordV1EmailSendRegistrationPasswordPost({
        organizationId,
        stateName,
    }: {
        organizationId: string,
        stateName: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/email/send_registration_password',
            query: {
                'organization_id': organizationId,
                'state_name': stateName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Proxy Taxbot
     * @returns any Successful Response
     * @throws ApiError
     */
    public static proxyTaxbotV1TaxgptGet({
        text,
    }: {
        /**
         * Text to be sent to chat
         */
        text: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/taxgpt',
            query: {
                'text': text,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
