import { useCallback, useEffect, useState } from 'react';
import { GetCity, GetCountries, GetState } from 'react-country-state-city';
import { LocationOptionType, UseLocationDataType } from 'types/location';
import { getSortedCountries } from 'utils/address';

export const useLocationData = (): UseLocationDataType => {
  const [countries, setCountries] = useState<LocationOptionType[]>([]);
  const [isLoadingCountries, setIsLoadingCountries] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Cache for country IDs to avoid repeated lookups
  const [countryIdMap, setCountryIdMap] = useState<Record<string, number>>({});

  // Fetch countries on mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countryList = (await GetCountries()) || [];
        const formattedCountries = countryList.map(country => ({
          value: country.iso2,
          label: country.name,
          id: country.id,
        }));

        // Build country ID map for faster lookups
        const idMap: Record<string, number> = {};
        countryList.forEach(country => {
          idMap[country.iso2] = country.id;
        });
        setCountryIdMap(idMap);

        setCountries(getSortedCountries(formattedCountries));
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch countries'));
      } finally {
        setIsLoadingCountries(false);
      }
    };

    fetchCountries();
  }, []);

  // Get states for a specific country
  const getStates = useCallback(
    async (countryCode: string): Promise<LocationOptionType[]> => {
      if (!countryCode) return [];

      try {
        const countryId = countryIdMap[countryCode] ?? 233; // default to US if not found
        const stateList = (await GetState(countryId)) ?? [];
        const formattedStates = stateList.map(state => ({
          value: state.state_code,
          label: state.name,
          id: state.id,
        }));
        return getSortedCountries(formattedStates);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch states'));
        return [];
      }
    },
    [countryIdMap]
  );

  // Get cities for a specific state and country
  const getCities = useCallback(
    async (countryCode: string, stateCode: string): Promise<LocationOptionType[]> => {
      if (!countryCode || !stateCode) return [];

      try {
        const countryId = countryIdMap[countryCode] ?? 0;
        const stateList = (await GetState(countryId)) ?? [];
        const stateId = stateList.find(state => state.state_code === stateCode)?.id ?? 0;

        const cityList = (await GetCity(countryId, stateId)) ?? [];
        return cityList.map(city => ({
          value: city.name,
          label: city.name,
          id: city.id,
        }));
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch cities'));
        return [];
      }
    },
    [countryIdMap]
  );

  return {
    countries,
    getStates,
    getCities,
    isLoadingCountries,
    error,
  };
};
