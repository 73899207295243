import { OrgMemberInfo } from '@propelauth/react';
import { FormatNumberOptions, Maybe, NumberStyleEnum } from 'types/utils';

/**
 * Formats a number or string into a formatted string representation.
 *
 * @param input - The number or string to be formatted.
 * @param options - The formatting options.
 * @returns The formatted string representation of the input.
 */
export const formatNumber = (
  input: Maybe<number | string>,
  {
    style = NumberStyleEnum.DECIMAL,
    zeroStr,
    currency,
    currencySign,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  }: FormatNumberOptions = {}
): string => {
  let value;
  if (input || input === 0) {
    value = Number(input);
  } else {
    return '';
  }
  if (zeroStr && value === 0) {
    return zeroStr;
  }

  if (maximumFractionDigits === 'max' || maximumFractionDigits > 16) {
    maximumFractionDigits = 16; // max supported by JS is documented as 20 but in practice seems to be 16
  }

  if (minimumFractionDigits > maximumFractionDigits) {
    minimumFractionDigits = maximumFractionDigits;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    currency,
    currencySign,
    signDisplay: value === 0 ? 'never' : 'auto',
  });
  const formatted = formatter.format(value);
  return formatted;
};

/**
 * Formats a number or string as a currency value.
 *
 * @param input - The number or string to format as currency.
 * @returns The formatted currency value.
 */
export const formatCurrency = (input: Maybe<number | string>) => {
  return formatNumber(input, {
    style: NumberStyleEnum.CURRENCY,
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Formats order_by fields into field's sort order.
 *
 * @param fields - The array of fields fetched from redux.
 * @param fieldName - The field name that needs sort order.
 * @returns The formatted asc or desc order of field.
 */

export const getFieldSortOrder = (fields: string[], fieldName: string) => {
  const field = fields.find(field => field.includes(fieldName));
  if (field) {
    return field.startsWith('-') ? 'desc' : 'asc';
  }
  return 'asc';
};

/**
 * Extracts the Chargebee site ID from a URL or returns the direct site ID.
 *
 * @param url - Either a full Chargebee URL (e.g., 'mysite.chargebee.com') or a direct site ID
 * @returns The extracted Chargebee site ID
 * @example
 * // Returns 'mysite'
 * extractChargebeeSiteId('mysite.chargebee.com')
 * // Returns 'mysite'
 * extractChargebeeSiteId('https://mysite.chargebee.com')
 * // Returns 'mysite'
 * extractChargebeeSiteId('mysite')
 */
export const extractChargebeeSiteId = (url: string): string => {
  if (!url) {
    return '';
  }

  const cleanUrl = url.trim();

  if (cleanUrl.toLowerCase().includes('chargebee.com')) {
    const withoutProtocol = cleanUrl.split('://').pop() || '';

    const siteId = withoutProtocol.split('.')[0];
    return siteId.toLowerCase();
  }
  return cleanUrl.toLowerCase();
};

/**
 * Generates a validation message for maximum length constraints.
 *
 * @param fieldName - The name of the field being validated
 * @param maxLength - The maximum allowed length for the field
 * @returns A formatted error message string
 * @example
 * // Returns "Name must be 50 characters or less"
 * getMaxLengthMessage("Name", 50)
 */
export const getMaxLengthMessage = (fieldName: string, maxLength: number): string => {
  return `${fieldName} must be ${maxLength} characters or less`;
};

/**
 * Converts PropelAuth organization data into a simplified organization structure.
 * Filters out organizations without an organization_id and extracts key properties.
 *
 * @param orgs - Array of PropelAuth organization member information
 * @returns Array of simplified organization objects containing name, id, external_id, and is_test status
 * @example
 * // Returns [{
 * //   name: "Acme Corp",
 * //   id: "123",
 * //   external_id: "456",
 * //   is_test: false
 * // }]
 * getOrgsFromPropelAuth(propelAuthOrgs)
 */
export const getOrgsFromPropelAuth = (orgs: OrgMemberInfo[]) => {
  return orgs
    .filter(({ orgMetadata }) => !!orgMetadata.organization_id)
    .map(({ orgId, orgMetadata, orgName }) => ({
      name: orgName,
      id: orgMetadata.organization_id,
      external_id: orgId,
      is_test: orgMetadata.is_test,
    }));
};

/**
 * Generates a random string that can be used as a state parameter.
 * Useful for CSRF protection in authentication flows.
 *
 * @returns A random string of characters
 * @example
 * // Returns something like "x7hq9"
 * generateState()
 */
export const generateState = () => (Math.random() + 1).toString(36).substring(7);

/**
 * Prepares a Stripe install link by combining multiple parameters into a single string.
 *
 * @param params - Object containing install link parameters
 * @param params.link - The base Stripe link
 * @param params.selectedOrgId - The ID of the selected organization
 * @param params.mode - The Stripe mode (e.g., 'TEST', 'LIVE')
 * @param params.state - A state parameter for CSRF protection
 * @returns A formatted string combining all parameters with ':' as separator
 * @example
 * // Returns "https://marketplace.stripe.com/oauth/v2/authorize?client_id=id_1&redirect_uri=https://connector.trykintsugi.com/v1/stripe/apps/oauth/authorize:abc123:org456:TEST"
 * prepareStripLink({
 *   link: "https://marketplace.stripe.com/oauth/v2/authorize?client_id=id_1&redirect_uri=https://connector.trykintsugi.com/v1/stripe/apps/oauth/authorize",
 *   selectedOrgId: "org456",
 *   mode: "TEST",
 *   state: "abc123"
 * })
 */
export const prepareStripInstallLink = ({
  link,
  selectedOrgId,
  mode,
  state,
}: {
  link: string;
  selectedOrgId: string;
  mode: string;
  state: string;
}) => {
  if (!link || !selectedOrgId || !mode || !state) {
    throw new Error('All parameters are required');
  }
  const params = [encodeURIComponent(state), encodeURIComponent(selectedOrgId), encodeURIComponent(mode)].join(':');
  return `${link}&state=${params}`;
};

/*
 * Formats a large number into a shortened format (e.g., 12k, 1.5M).
 *
 * @param value - The number to format.
 * @returns The formatted number string.
 */
export const formatLargeNumberIntoShortFormat = (value: number): string => {
  if (value >= 1_000_000_000) return (value / 1_000_000_000).toFixed(1) + 'B'; // Billion format
  if (value >= 1_000_000) return (value / 1_000_000).toFixed(1) + 'M'; // Million format
  if (value >= 1_000) return (value / 1_000).toFixed(1) + 'k'; // Thousand format
  return value.toString();
};
