import { ExperienceService, NexusService } from '_api-client';
import { OrganizationStatsData, OrganizationTasksData } from 'types/charts';
import { ApiResponse, NexusInstance, TableFilter, TaxDetailsFilterProps } from 'types/shared-types';

import api from './api';

export const DASHBOARD_SERVICE_QUERY_KEYS = {
  base: ['dashboard'] as const,
  onboardingStepStatus: (orgId: string) =>
    [...DASHBOARD_SERVICE_QUERY_KEYS.base, orgId, 'onboarding-step-status'] as const,
};

const getSummary = (orgId: string): Promise<any> => {
  return ExperienceService.getDashboardDataRouteV1DashboardGet({ xOrganizationId: orgId });
};

const getNexus = ({ orgId, params }: { orgId: string; params?: TableFilter }) => {
  return api.get<ApiResponse<NexusInstance>>(`/v1/nexus`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const exportNexus = (orgId: string): Promise<any> => {
  return NexusService.getNexusReportV1NexusExportGet({ xOrganizationId: orgId });
};

const getDashboardDetails = (orgId: string, params: TaxDetailsFilterProps): Promise<any> => {
  return ExperienceService.getDashboardDetailsV1DashboardDetailsAggregationsAggregationTypeGet({
    xOrganizationId: orgId,
    aggregationType: 'annual',
    ...params,
  });
};

const getDashboardOnboardingStepStatus = (orgId: string) => {
  return api.get(`/v1/onboarding/steps-status`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getDashboardTasks = (orgId: string) => {
  return api.get<OrganizationTasksData>(`/v1/dashboard/tasks`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getDashboardStats = (orgId: string) => {
  return api.get<OrganizationStatsData>(`/v1/dashboard/stats`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const NEXUS_STATE_KEY = 'nexus';
const NON_EXPOSED_NEXUS_STATE_KEY = 'non-exposed-nexus';
const ONBOARDING_STATE_KEY = 'onboarding';

export {
  exportNexus,
  getDashboardDetails,
  getDashboardOnboardingStepStatus,
  getDashboardStats,
  getDashboardTasks,
  getNexus,
  getSummary,
  NEXUS_STATE_KEY,
  NON_EXPOSED_NEXUS_STATE_KEY,
  ONBOARDING_STATE_KEY,
};
