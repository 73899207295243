import { addMinutes, format as formatDate, isValid, parseISO, subMinutes } from 'date-fns';
import isString from 'lodash/isString';
import orderBy from 'lodash/orderBy';
import { Maybe } from 'types/utils';

/**
 * Orders an array of data objects by a specified field in descending order.
 *
 * @param data - The array of data objects to be sorted.
 * @param sortByField - The field to sort the data objects by.
 * @returns The sorted array of data objects.
 */
export const orderByDate = (data: Array<Record<string, any>> | unknown, sortByField: string) => {
  if (Array.isArray(data)) {
    return orderBy(data, [sortByField], ['asc']);
  }
  return [];
};

/**
 * Checks if a value is a valid Date object.
 * If the argument is an instance of Date, the function return true.
 * If the argument is a number, it is treated as a timestamp and return true if valid date
 * If the argument is none of the above, the function return false.
 * @param date - The value to be checked.
 * @returns True if the value is a valid Date object, false otherwise.
 */
export const isValidDate = (date: unknown): date is Date => {
  return date instanceof Date && isValid(date);
};

/**
 * Formats a date string or Date object into the specified format.
 * If the input date is invalid or empty, an empty string is returned.
 *
 * @param date - The date string or Date object to format.
 * @param format - The format string to use for formatting the date.
 * @returns The formatted date string.
 */
export const getFormattedDate = (date: Maybe<string | Date>, format: string): string => {
  let dateToFormat: Date | null = null;
  if (isValidDate(date)) {
    dateToFormat = date; // typescript knows 100% that date is type Date
    formatDate(dateToFormat, format);
  } else if (isString(date) && date.trim() !== '') {
    dateToFormat = parseISO(date);
  }
  return dateToFormat && isValid(dateToFormat) ? formatDate(dateToFormat, format) : '';
};

/**
 * Converts a date string or Date object to a short date format.
 * @param date - The date string or Date object to be formatted.
 * @returns The formatted date string in the format 'MMM d, yyyy' e.g. Nov 30, 2023
 */
export const toDateShort = (date: Maybe<string | Date>) => {
  return getFormattedDate(date, 'MMM d, yyyy');
};

/**
 * Converts a date string or Date object to a long date format.
 * @param date - The date string or Date object to be converted.
 * @returns The long date string in the format "MMMM d, yyyy" e.g. Nov 30, 2023
 */
export const toDateLong = (date: Maybe<string | Date>): string => {
  return getFormattedDate(date, 'MMMM d, yyyy');
};

/**
/**
 * Adjusts the time zone offset of a given date with UTC.
 * If the time zone offset is negative, it subtracts the absolute value of the offset from the date.
 * If the time zone offset is positive, it adds the offset to the date.
 *
 * @param date - The date to adjust the time zone offset for.
 * @returns The adjusted date.
 *
 * @example
 * const date = new Date('2022-01-01T00:00:00');
 * const adjustedDate = adjustTimeZoneOffsetWithUtc(date);
 * console.log(adjustedDate); // Output: 2022-01-01T00:00:00Z (UTC)
 */
export const adjustTimeZoneOffsetWithUtc = (date: Date): Date => {
  const timeZoneOffset = date.getTimezoneOffset();
  return Math.sign(timeZoneOffset) === -1
    ? subMinutes(date, Math.abs(timeZoneOffset))
    : addMinutes(date, timeZoneOffset);
};
