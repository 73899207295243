import { CountryCodeEnum } from '_api-client';
import { DocumentIcon } from 'components/icons';
import { CountriesByCode } from 'constants/app-constants';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import { MdOutlineImage } from 'react-icons/md';
import { CanadaTaxExemptionJurisdictionEnum, CanadaTaxJurisdictionEnum, LocationOptionType } from 'types/location';

export const toNormalCase = (str: string) => {
  return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

export const replaceUnderscoreWithSpace = (str: string) => {
  if (!str) return str;
  return str.replace(/_/g, ' ');
};

/**
 * Format text to capitalize every first letter
 * @param text
 * @returns string
 */
export const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;
  return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

/**
 * Convert null value to empty string. Supported type object/ArrayOfObject only.
 * @param value object/ArrayOfObject
 */
export const replaceNullWithEmptyString = (values: any): any => {
  if (!values) {
    return;
  }

  if (typeof values === 'object' && values.constructor === Object) {
    return mapValues(values, v => (isNil(v) ? '' : v));
  }

  if (Array.isArray(values)) {
    return values.map(value => mapValues(value, v => (isNil(v) ? '' : v)));
  }
};

/**
 * Generate 6 digit random number
 */
export const generateRandomNumber = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

/**
 * Get file extension from filename
 * @param filename string
 * @returns string
 */
export const getFileExtension = (filename: string): string => {
  return filename?.split('.').pop()?.toLowerCase() ?? '';
};

export const getAttachmentIcon = (fileType: string) => {
  const attachmentIconMap: { [key: string]: React.ComponentType } = {
    jpg: MdOutlineImage,
    jpeg: MdOutlineImage,
    png: MdOutlineImage,
    gif: MdOutlineImage,
    pdf: DocumentIcon,
    doc: DocumentIcon,
    docx: DocumentIcon,
    txt: DocumentIcon,
  };

  return attachmentIconMap[fileType.toLowerCase()] || DocumentIcon;
};

export const getCountryDisplay = (code: CountryCodeEnum | undefined | null | string) => {
  if (!code) return '';
  if (code === CountryCodeEnum.US) return code;
  return CountriesByCode[code]?.label || code;
};

/**
 * Get the country name by code
 * @param code CountryCodeEnum | undefined | null | string
 * @returns string [if code is US return United States, for other country return country name]
 */
export const getCountryNameByCode = (code: CountryCodeEnum | undefined | null | string) => {
  if (!code) return '';
  if (code === CountryCodeEnum.US) return 'United States';
  return CountriesByCode[code]?.label || code;
};

/**
 * Get the tax jurisdictions options for Canada
 * @param states LocationOptionType[]
 * @returns LocationOptionType[]
 */
export const formatCanadianProvinceOptions = (states: LocationOptionType[]) => {
  const federalOption: LocationOptionType = {
    value: CanadaTaxJurisdictionEnum.FEDERAL,
    label: 'Canada (Federal)',
  };

  const provinceOptions = states.filter(state =>
    Object.values(CanadaTaxJurisdictionEnum).includes(state.value as CanadaTaxJurisdictionEnum)
  );

  // Combine and sort alphabetically by label
  return [...provinceOptions, federalOption].sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Get the tax exemption jurisdictions options for Canada
 * @param states LocationOptionType[]
 * @returns LocationOptionType[]
 */
export const getCanadaTaxExemptionJurisdictions = (states: LocationOptionType[]) => {
  const provinceOptions = states.filter(state =>
    Object.values(CanadaTaxExemptionJurisdictionEnum).includes(state.value as CanadaTaxExemptionJurisdictionEnum)
  );
  return provinceOptions.sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Determines if a word should be shown in plural or singular form.
 * Examples:
 * * `pluralize('result', 1) => 'result'`
 * * `pluralize('result', 42) => 'results'`
 *
 * @param word String that will be turned into plural based on number of items
 * @param length number of items
 * @param plural defaults to `s` but can be changed based on the base word
 * @returns singular or plural version of the word
 */
export const pluralize = (word: string, length: number, plural = 's'): string => {
  if (length > 1) {
    return `${word}${plural}`;
  }
  return word;
};

export const getCurrencyCodeByCountryCode = (countryCode: CountryCodeEnum | undefined | null | string) => {
  const currencyCodeMap: { [key: string]: string } = {
    [CountryCodeEnum.AU]: 'AUD',
    [CountryCodeEnum.CA]: 'CAD',
    [CountryCodeEnum.US]: 'USD',
  };

  if (!countryCode) return '';
  return currencyCodeMap[countryCode] || countryCode;
};
