import { Circle, Flex, HStack, Icon } from '@chakra-ui/react';
import { LinkButton } from 'components/ui/link-button';
import { StatLabel, StatRoot, StatValueText } from 'components/ui/stat';

type TaxProcessStatsProps = {
  count: number;
  title: string;
  icon: React.ReactNode;
  buttonLabel?: string;
  onReviewClick?: () => void;
  reviewHref?: string;
  href?: string;
};

const TaxProcessStats = ({
  count,
  title,
  icon,
  buttonLabel = 'Review',
  onReviewClick,
  reviewHref,
  href,
}: TaxProcessStatsProps) => (
  <Flex align="center" justify="start" w="100%" gap={4} hidden={count == 0}>
    <Circle bg="secondary.50" tabIndex={-1} aria-label="icon" size="10">
      <Icon w="6" h="6" color="secondary.500">
        {icon}
      </Icon>
    </Circle>
    <StatRoot me="auto">
      <StatValueText fontSize="md" fontWeight="medium" lineHeight="none">
        {count}
      </StatValueText>
      <StatLabel color="gray.900" fontWeight="normal">
        {title}
      </StatLabel>
    </StatRoot>
    <HStack>
      {reviewHref && (
        <LinkButton width={'140px'} href={reviewHref} variant={'outline'} colorPalette="blue" onClick={onReviewClick}>
          {buttonLabel}
        </LinkButton>
      )}

      {href && (
        <LinkButton width={'140px'} href={href} variant={'outline'} colorPalette="blue">
          Bulk Cleanup
        </LinkButton>
      )}
    </HStack>
  </Flex>
);

export default TaxProcessStats;
