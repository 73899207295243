import { Badge, Box, Flex, HStack, Icon, Separator, Stack, Text } from '@chakra-ui/react';
import { DatabaseIcon, LocationIcon, NoTaskIcon, ProductIcon } from 'components/icons';
import { NEXUS_ROUTES } from 'constants/AppRoutes';
import { Fragment } from 'react';
import { ProductStatusEnum } from 'schema/types-schema.d';
import { OrganizationTasksData } from 'types/charts';
import { pluralize } from 'utils';

import { AddressIcon, FilingIcon, RegisterIcon } from './dashboard-icons';
import TaxProcessStats from './tax-process-stats';

type TasksProps = {
  hasTransactions: boolean;
  data: OrganizationTasksData;
};

type Task = {
  count: number;
  title: string;
  icon: JSX.Element;
  href?: string;
  reviewHref?: string;
  onReviewClick?: () => void;
  buttonLabel?: string;
};

export const Tasks = ({
  hasTransactions,
  data: {
    registrations_to_finish,
    filings_to_approve,
    invalid_addresses,
    blank_addresses,
    pending_product,
    registrations_regime_to_change,
    registrations_regime_to_acknowledge,
    needs_update_connections_count,
  },
}: TasksProps) => {
  const tasks: Task[] = [
    {
      count: needs_update_connections_count,
      title: 'Connection to Update',
      icon: <DatabaseIcon key="connection" />,
      reviewHref: `/source`,
    },
    {
      count: registrations_regime_to_change,
      title: `${pluralize('Registration', registrations_regime_to_change)} Regime to Change`,
      icon: <RegisterIcon key="regime" />,
      reviewHref: `/registrations`,
    },
    {
      count: registrations_regime_to_acknowledge,
      title: `Regime ${pluralize('Change', registrations_regime_to_acknowledge)} to Acknowledge`,
      icon: <RegisterIcon key="regime_updated" />,
      reviewHref: `/registrations`,
    },
    {
      count: registrations_to_finish,
      title: `${pluralize('Registration', registrations_to_finish)} to Finish`,
      icon: <RegisterIcon key="register" />,
      reviewHref: NEXUS_ROUTES.EXPOSED,
    },
    {
      count: filings_to_approve,
      title: `${pluralize('Filing', filings_to_approve)} to Approve`,
      icon: <FilingIcon key="filing" />,
      reviewHref: '/filing',
    },
    {
      count: invalid_addresses,
      title: `Invalid ${pluralize('Address', invalid_addresses)}`,
      icon: <AddressIcon key="address" />,
      href: '/invalid-addresses',
    },
    {
      count: blank_addresses,
      title: `Blank ${pluralize('Address', blank_addresses)}`,
      href: '/blank-addresses',
      icon: <LocationIcon key="location" color="primary.50" />,
      buttonLabel: 'Add',
    },
    {
      count: pending_product,
      title: `${pluralize('Product', pending_product)} to Approve`,
      icon: <ProductIcon key="product" />,
      reviewHref: `/products?status__in=${ProductStatusEnum.PARTIALLY_APPROVED},${ProductStatusEnum.PENDING}`,
    },
  ];

  const visibleTasks = tasks.filter(task => task.count > 0);

  if (visibleTasks.length === 0) {
    return (
      <Box
        border={'1px solid var(--gray-100, #CFD0D8)'}
        borderRadius={'4px'}
        alignContent={{ base: 'center', md: 'center', sm: 'flex-end' }}
        pb={{ base: 'none', md: 'none', sm: 6 }}
        position={'relative'}
        height={'421px'}
      >
        <Text fontWeight={500} fontSize={'18px'} position={'absolute'} top={'19px'} left={4}>
          Tasks
        </Text>
        <Stack align={'center'} gap={1}>
          <Icon bgColor={'secondary.50'} height={'42px'} width={'42px'} borderRadius={'6px'} p={'9px'}>
            <NoTaskIcon />
          </Icon>

          <Text fontWeight={500} mt={4} fontSize="sm">
            No Tasks {!hasTransactions && 'Yet'}
          </Text>
          <Text fontSize="sm">
            {!hasTransactions ? 'Start with importing transactions and check back again.' : "You're all caught up."}
          </Text>
        </Stack>
      </Box>
    );
  }

  return (
    <Flex
      direction={'column'}
      border={'1px solid var(--gray-100, #CFD0D8)'}
      borderRadius={'4px'}
      px={4}
      overflowY={'auto'}
      height={'421px'}
      gap={4}
      pb={4}
    >
      <HStack pt={4}>
        <Text fontWeight={500} fontSize={'18px'}>
          Pending Tasks
        </Text>
        <Badge colorPalette={'red'}>Action Required</Badge>
      </HStack>

      {visibleTasks.map((task, index) => (
        <Fragment key={index}>
          <TaxProcessStats
            count={task.count}
            title={task.title}
            icon={task.icon}
            href={task.href}
            reviewHref={task.reviewHref}
            onReviewClick={task.onReviewClick}
            buttonLabel={task.buttonLabel}
          />
          {index < visibleTasks.length - 1 && <Separator hidden={task.count == 0} />}
        </Fragment>
      ))}
    </Flex>
  );
};
