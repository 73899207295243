import { useQuery } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, getConnections } from 'apis/connections';
import { getInitialImportStatus, TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import AlertBanner from 'components/alert/alert';
import { useOrg } from 'hooks/useOrg';
import { ApiResponse, ConnectionInstance } from 'types/shared-types';

const ImportProgressAlert = () => {
  const { orgId } = useOrg();

  const { isPending: connectionDataPending, data: hasConnections } = useQuery<
    ApiResponse<ConnectionInstance>,
    unknown,
    boolean
  >({
    queryKey: [CONNECTIONS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getConnections(orgId, 'ACTIVE');
      return data;
    },
    select: data => {
      return data?.items.length > 0;
    },
    enabled: !!orgId,
  });

  const { isPending, data: statusData } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, 'TRANSACTION_STATUS', orgId],
    queryFn: async () => {
      const res = await getInitialImportStatus(orgId);
      return res.data;
    },
  });

  if (connectionDataPending || isPending || statusData?.initial_import_complete_status) {
    return null;
  }

  if (!hasConnections) {
    return null;
  }

  return (
    <AlertBanner
      message="Transaction import is still in progress, so the numbers may not be the latest. We’ll email you once the
        import is complete."
      width={{ xl: '80%' }}
    />
  );
};

export default ImportProgressAlert;
