import { handleApiErrorMessage, isAxiosError, isAxiosErrorMatchedStatus, staticErrorMessages } from 'apis/api';
import { AxiosError } from 'axios';
import { toaster } from 'components/ui/toaster';
import { useNavigate } from 'react-router-dom';

type UseHandleNotificationReturn = {
  handleFailNotification: (error: AxiosError | undefined | Error | unknown, route?: string) => void;
  handleSuccessNotification: (message: string, duration?: number) => void;
};

export const useHandleNotification = (): UseHandleNotificationReturn => {
  const navigate = useNavigate();

  const handleFailNotification = (error: AxiosError | undefined | Error | unknown, route?: string) => {
    if (isAxiosError(error)) {
      const errorMessage = handleApiErrorMessage(error);
      const errorStatus = error?.status as keyof typeof staticErrorMessages;
      const errorMsgTitle = errorMessage?.title ?? `Request failed with status code ${errorStatus}`;
      const errorMsgDescription = errorMessage?.message || staticErrorMessages[errorStatus];

      toaster.create({ type: 'error', title: errorMsgTitle, description: errorMsgDescription });

      // If you navigate to the specified route for a specific error status code
      if (route && isAxiosErrorMatchedStatus(error, 400, 404)) {
        navigate(route, { replace: true });
      }
    }
  };

  const handleSuccessNotification = (message: string, duration?: number) => {
    toaster.create({ type: 'success', title: 'Success', description: message, duration });
  };

  return { handleFailNotification, handleSuccessNotification };
};
