import { Input, JsxStyleProps } from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from 'components/icons';
import { InputGroup } from 'components/ui/input-group';
import debounce from 'lodash/debounce';
import { forwardRef, useImperativeHandle, useRef } from 'react';

export type SearchInputRef = {
  resetQuery: () => void;
};

export type SearchInputProps = {
  placeholder?: string;
  query: string;
  setQuery: (query: string) => void;
  styles?: JsxStyleProps;
};

const SearchInput = forwardRef<SearchInputRef, SearchInputProps>(
  ({ placeholder = 'Search', query, setQuery, styles }, ref) => {
    const localRef = useRef<HTMLInputElement | null>(null);

    const handleQueryChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery((event.target as HTMLInputElement).value);
    }, 500);

    const handleEnter = debounce((event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const inputValue = (event.target as HTMLInputElement).value;
        setQuery(inputValue);
      }
    }, 100);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === ' ') {
        event.stopPropagation(); // prevent parent from closing
      }

      handleEnter(event);
    };

    const handleQueryReset = () => {
      if (localRef.current) {
        localRef.current.value = '';
      }
      setQuery('');
    };

    useImperativeHandle(ref, () => ({
      resetQuery: handleQueryReset,
    }));

    const { width: inputWidth = '100%' } = styles || {};

    return (
      <InputGroup
        {...styles}
        width={{ base: inputWidth as number | string, md: inputWidth as number | string, sm: '220px' }}
        startElement={<SearchIcon />}
        endElement={!!query && <CloseIcon style={{ cursor: 'pointer' }} onClick={handleQueryReset} />}
      >
        <Input
          ref={localRef}
          name="global-search-input"
          placeholder={placeholder}
          defaultValue={query}
          onKeyDown={handleKeyDown}
          onChange={handleQueryChange}
          required={false}
          _focus={{
            borderColor: 'secondary.500',
          }}
        />
      </InputGroup>
    );
  }
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
